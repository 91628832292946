import { toast } from "react-toastify";
import API from "../../services/Api"
import { USER_DETAILS_DATA, USER_LIST_DATA } from "../Constant/constant";

export const userListAction=(page,search)=>async(dispatch)=>{
    try {
     var userlist= await  API.get(`userList?page=${ page == undefined ? 1 : page
      }&search=${search}`)
    

     if( userlist.data.statuscode==200){
        dispatch({
            type:USER_LIST_DATA,
            payload: userlist.data.data
        })
     }else{

        toast.error(userlist.data.message);
     }
    } catch (error) {
        toast.error(error.message);
    }
}
export const userdetailsAction=(id)=>async(dispatch)=>{
    try {
        var userlist_Details= await  API.get(`userdetails?id=${id}`)
      
  
       if( userlist_Details.data.statuscode==200){
          dispatch({
              type:USER_DETAILS_DATA,
              payload: userlist_Details.data.data
          })
       }else{
  
          toast.error(userlist_Details.data.message);
       }
    } catch (error) {
        toast.error(error.message);
    }
}


export const Update_user_detailsAction = (data) => async (dispatch) => {
    try {
      const res = await API.post("/Update_user_details", data);
      if (res?.data.status==200) {
    
        toast.success(res?.data.message);
       
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };



  export const updatepassworduserAction = (data) => async (dispatch) => {
    try {
      const res = await API.post("/updatepassworduser", data);
      if (res?.data.status==200) {
        toast.success(res?.data.message);
       
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  

  export const getAllstaticapiAction = (data) => async (dispatch) => {
    try {
      const res = await API.get("/getAllstaticapi", data);
      if (res?.data.status_code==200) {
       return res.data.data
       
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  
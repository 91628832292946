import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { ThemeContext } from "../../../../context/ThemeContext";
import { navtoggle } from "../../../../store/actions/AuthActions";
import logo from "../../../../images/background/favicon.png";
import text from "../../../../images/background/white_logo.svg";

const NavHader = () => {
  const [toggle, setToggle] = useState(false); 
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <div className="nav-header"> 
      <Link to="/dashboard" className="brand-logo">
      <img src={logo} className="theme-logo-o logo-abbr" style={{width:"30px", height:"30px", viewBox:"0 0 47 16"}}/>
      <img src={text} className="brand-title" style={{width:"79px", height:"27px", viewBox:"0 0 47 16"}}/>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;

import { SVGICON } from "../../../constant/theme";

export const MenuList = [

    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: SVGICON.Home,
        to: '/dashboard',
    
    },
    {   
        title:'User Management',
        iconStyle: SVGICON.Employe,
        to: '/user-management',
    },
    // {   
    //     title:'Experience',
    //     iconStyle: SVGICON.Reports,
    //     to: '/experience',
    // },
	
    // {   
    //     title:'Reports',
    //     iconStyle: SVGICON.Reports,
    //     to: '/reports',
    // },
	
    // {   
    //     title:'Manage Admins',
    //     iconStyle: SVGICON.ManageClient,
    //     to: '/manage-client',
    // },
 
    
]
import React from 'react'
import Modal from "react-bootstrap/Modal";
const DeleteModal = ({ viewVal, setShowVal}) => {
    const handleClose = () => {
        setShowVal(false);
      };
  return (
    <Modal
    show={viewVal}
    onHide={handleClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="delete_account_modal"
  >
    {/* <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {modalTitle[modalName]}
      </Modal.Title>
    </Modal.Header> */}
    <Modal.Body>
      <h4 className="text-center mb-0 delete_content">
    Want delete
      </h4>
      <div className="d-flex justify-content-center delete_acc_btns">
        <button
          onClick={handleClose}
          type="submit"
          className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
        >
          Yes
        </button>
        <button
          onClick={handleClose}
          type="submit"
          className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
        >
          No
        </button>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default DeleteModal
import React,{useContext, useEffect} from 'react';
import Layout from "../components/layouts/Layout";
import MainPagetitle from '../components/layouts/MainPagetitle';
import CardWidget from '../components/elements/CardWidget';
import ToDoList from '../components/elements/ToDoList';
import EarningBlog from '../components/elements/EarningBlog';
import ActiveUserMap from '../components/elements/ActiveUserMap';
import ProjectStatusBlog from '../components/elements/ProjectStatusBlog';
 
const Dashboard = () =>{
    return (
        <>	
        <Layout>
        <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-9 wid-100">
                    <div className="row">
                        <CardWidget />
                        <div className="col-xl-8">
                        <EarningBlog />
                        </div>
                        <div className="col-xl-4">
                            <ToDoList />
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 t-earn">
                <ActiveUserMap />	
                </div>
                <div className="col-xl-3 col-md-6 up-shd">
                    <ProjectStatusBlog  title="Platforms"/>
                </div>
            </div>							
        </div>		
         </Layout>
    </>
    )
}

export default Dashboard;
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import place_holder from "../../images/tab/1.svg"
import { IMAGES } from "../constant/theme";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import Search from "../components/elements/Search";
import { useDispatch, useSelector } from "react-redux";
import { userListAction } from "../../store/actions/UserManagementAction";


const tableData = [
  {
    emplid: "1001",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky Antony",
    email: "ra@gmail.com",
    gender: "Female",
    location: "India",
  },
  {
    emplid: "1002",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ankites Risher",
    email: "abc@gmail.com",
    gender: "Male",
    location: "Brazil",
  },
  {
    emplid: "1003",
    department: "Computer Science",
    image: IMAGES.contact3,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky M",
    email: "pqr@gmail.com",
    gender: "Male",
    location: "France",
  },
  {
    emplid: "1004",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Elijah James",
    email: "stuy@gmail.com",
    gender: "Female",
    location: "Dubai",
  },
  {
    emplid: "1005",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Honey Risher",
    email: "xyz@gmail.com",
    gender: "Male",
    location: "USA",
  },
  {
    emplid: "1006",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Honey Risher",
    email: "xyz@gmail.com",
    gender: "Male",
    location: "USA",
  },
  {
    emplid: "1007",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ankites Risher",
    email: "abc@gmail.com",
    gender: "Male",
    location: "Brazil",
  },
  {
    emplid: "1008",
    department: "Computer Science",
    image: IMAGES.contact3,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky M",
    email: "pqr@gmail.com",
    gender: "Male",
    location: "France",
  },
  {
    emplid: "1009",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ricky Antony",
    email: "ra@gmail.com",
    gender: "Female",
    location: "India",
  },
  {
    emplid: "1010",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Elijah James",
    email: "stuy@gmail.com",
    gender: "Female",
    location: "Dubai",
  },
  {
    emplid: "1011",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ankites Risher",
    email: "abc@gmail.com",
    gender: "Male",
    location: "Brazil",
  },
  {
    emplid: "1012",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky Antony",
    email: "ra@gmail.com",
    gender: "Female",
    location: "India",
  },
];
const UserManagement = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;


  useEffect(()=>{
    dispatch(userListAction(page, search))
  },[page,search])

  var Userlist=useSelector((state)=>state.Usermanagement?.user_list)



  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = tableData.slice(firstIndex, lastIndex);
  const npage = Math.ceil((Userlist?.totallength==undefined?0:Userlist?.totallength) / 10);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }


console.log(search)


 
  return (
    <Layout>
      <MainPagetitle
        mainTitle="User Management"
        pageTitle={"User Management"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">User Management</h4>
                    <Search   setSearch={setSearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>User Name</th>
                          <th>Email Address</th>
                          <th>Account Type</th>
                          <th>Contact Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Userlist?.user_list?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span>{index+1}</span>
                            </td>
                            <td>
                              <div className="products" style={{ cursor: 'pointer' }} onClick={() => navigate(`/user-detail/${item._id}`)}>
                                <img
                                  src={item.user_pic==""?   place_holder:process.env.REACT_APP_FILE_BASE_URL+"profileimages/"+item.user_pic}
                                  className="avatar avatar-md"
                                  alt=""
                                />
                                <div >
                                  <h6>{item.full_name}</h6>
                              
                                </div>
                              </div>
                            </td>
                            {/* <td><span>{item.department}</span></td> */}
                            <td>
                              <span className="text-primary">
                                {item.email}
                              </span>
                            </td>
                            <td>
                              <span>{item.role==0?"Candidate":"Employeer"}</span>
                            </td>
                            <td>
                              <span>   {item.country_code==undefined  || item?.country_code ==""   ?"N/A":item.country_code+item.phone_number}</span>
                            </td>
                         
                          </tr>
                        ))}
                      </tbody>
                    </table>









                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {tableData.length < lastIndex
                          ? tableData.length
                          : lastIndex}{" "}
                        of {tableData.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${currentPage === n ? "current" : ""
                                } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default UserManagement
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoutPage from "./Logout";
import { IMAGES, SVGICON } from "../../../constant/theme";
import { ThemeContext } from "../../../../context/ThemeContext";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Button } from "@mui/material";
import { useContext } from "react";
import place_holder from "../../../../images/tab/1.svg";
const Header = ({ onNote }) => {
  const [headerFix, setheaderFix] = useState(false);
  const [ligtDark, setLightDark] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    if (ligtDark == true) {
      changeBackground({ value: "light", label: "light" });
    } else if (ligtDark == false) {
      changeBackground({ value: "light", label: "light" });
    }
  }, [ligtDark]);
 

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right">
              {/* <li className="nav-item align-items-center header-border">
                <Button onClick={() => setLightDark((prev) => !prev)}>
                  {ligtDark ? <DarkModeIcon /> : <LightModeIcon />}
                </Button>
              </li> */}
              <li className="nav-item ps-3">
                <Dropdown className="header-profile2">
                  <Dropdown.Toggle className="nav-link i-false" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      <div className="header-media">
                        <img src={place_holder} alt="" />
                      </div>
                      <div className="header-info">
                        <h6>{sessionStorage.getItem('name')}</h6>
                        <p>{sessionStorage.getItem('email')}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div className="card border-0 mb-0">
                      <div className="card-header py-2">
                        <div className="products">
                          <img
                            src={place_holder}
                            className="avatar avatar-md"
                            alt=""
                          />
                          <div>
                          
                            <span>Admin</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer px-0 py-2">
                        <Link
                          to={"/change-password"}
                          className="dropdown-item ai-icon "
                        >
                          {SVGICON.Headersetting}{" "}
                          <span className="ms-2">Change Password </span>
                        </Link>
                        <LogoutPage />
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

import {  Route, Routes} from 'react-router-dom';
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Login from './jsx/pages/Login';
import Dashboard from './jsx/pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import UserManagement from './jsx/pages/UserManagement';
import Report from './jsx/pages/Report';
import ManageAdmins from './jsx/pages/ManageAdmins';
import ChangePassword from './jsx/pages/ChangePassword';
import Detail from './jsx/components/user_detail/Detail';
import PrivateRoute from './jsx/routes/PrivateRoute';
import Experience from './jsx/pages/Experience';
import { Suspense } from 'react';

function App () {
   
  return (
    <div className="vh-100">
    <ToastContainer limit={1} />
    
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route element={<PrivateRoute />}>
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/reports' element={<Report />} />
        <Route path='/manage-client' element={<ManageAdmins />} />
        <Route path='/user-management' element={<UserManagement />} />
        <Route path='/experience' element={<Experience />} />
        <Route path='/user-detail/:id' element={<Detail />} />
        </Route>   
      </Routes> 

    </div>
  );
};



export default App; 


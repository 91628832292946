import React, { useState } from 'react'

const Search =  ({setSearch,setPage}) => {
  const [searchData, setSearchData] = useState("");
  const handleSearch = (e) => {
      function onlyLettersAndSpaces(str) {
        return /^[A-Za-z\s]*$/.test(str);
      }

      if(onlyLettersAndSpaces(e)){
        setSearch(e);
        setSearchData(e)
        setPage(1);
      }
  
  
    };
  return (
    <div className="">
     <input
      value={searchData?.trimStart()}
      type="text"
      className="form-control rounded-1"
      placeholder="Search"
      onChange={(e) => handleSearch(e.target.value)}
   
    />
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="workshop-search"
        >
          <circle
            cx="8.78605"
            cy="8.78605"
            r="8.23951"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round" 
          />
          <path
            d="M14.5168 14.9447L17.7471 18.1667"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      
  </div>
  )
}

export default Search
import React from 'react'
import Layout from '../components/layouts/Layout'
import MainPagetitle from '../components/layouts/MainPagetitle'
import Search from '../components/elements/Search'

export default function Experience() {
  return (
    <Layout>
    <MainPagetitle
      mainTitle="Experience"
      pageTitle={"Experience"}
      parentTitle={"Home"}
    />


<div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects  ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Experience</h4>
                    <Search    />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0 table-text-left"
                    >
                      <thead>
                        <tr>
                          <th className='fix-width-th'>ID</th>
                          <th>Title</th>
                        </tr>
                      </thead>
                      <tbody>
                 <tr>
                    <td className='fix-width-th'>1</td>
                    <td>Jitendra</td>
                 </tr>
                      </tbody>
                    </table>









                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       </Layout>
  )
}

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/background/black_logo.svg";
import LogoWhite from "../../images/background/white_logo.svg";
import bg6 from "../../images/background/Banner.jpg";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Adminlogin } from "../../store/actions/AuthActions";

function Login(props) {
  const [heartActive, setHeartActive] = useState(true);
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is Required*";
    }
    if (!values.password) {
      errors.password = "Password is Required*";
    }
    return errors;
  };


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
    
      dispatch(Adminlogin(values));
    },
  });



  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
        >
          <div className="row gx-0">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                style={{ maxHeight: "653px" }}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    dir: "ltr",
                  }}
                >
                  <div className="login-form style-2">
                    <div className="card-body">
                      <div className="logo-header">
                        <Link to={"#"} className="logo">
                          <img
                            src={logo}
                            alt=""
                            className="width-230 light-logo"
                          />

                        </Link>
                        <Link to={"#"} className="logo">
                          <img
                            src={LogoWhite}
                            alt=""
                            className="width-230 dark-logo"
                          />
                        </Link>
                      </div>
                      <div className="nav nav-tabs border-bottom-0">
                        <div className="tab-content w-100" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-personal"
                          >
                            {props.errorMessage && (
                              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                                {props.errorMessage}
                              </div>
                            )}
                            {props.successMessage && (
                              <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                                {props.successMessage}
                              </div>
                            )}
                            <form
                              className=" dz-form pb-3"
                              onSubmit={formik.handleSubmit}
                            >
                              <h3 className="form-title m-t0">
                                Login
                              </h3>
                              <div className="dz-separator-outer m-b5">
                                <div className="dz-separator bg-primary style-liner"></div>
                              </div>
                              <p>
                                Enter your e-mail address and your password.{" "}
                              </p>  
                              <div className="form-group mb-3">
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                  placeholder="Please Enter email"
                                />
                                {formik.errors.email && (
                                  <div className="text-danger fs-12">
                                    {formik.errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="form-group mb-3">
                                <input
                                  type="password"
                                  name="password"
                                  className="form-control"
                                  value={formik.values.password.trim()}
                                  onChange={formik.handleChange}
                                  placeholder="Please Enter password"
                                />
                                {formik.errors.password && (
                                  <div className="text-danger fs-12">
                                    {formik.errors.password}
                                  </div>
                                )}
                              </div>
                              <div className="form-group text-left mb-5">
                                <button
                                  type="submit"
                                  className="btn btn-primary dz-xs-flex m-r5"
                                >
                                  Login
                                </button>
                           
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                        <div className="col-lg-12 text-center">
                          <span>
                            {" "}
                            © Copyright by{" "}
                            <span
                              className={`heart ${heartActive ? "" : "heart-blast"
                                }`}
                              onClick={() => setHeartActive(!heartActive)}
                            ></span>
                            <a
                              target="_blank"
                            >
                              {" "}
                              Real Hire{" "}
                            </a>{" "}
                            All rights reserved.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div className="bg-img-fix overflow-hidden"
                style={{ background: "#fff url(" + bg6 + ")", height: "100vh", backgroundSize: "cover" }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import { USER_DETAILS_DATA, USER_LIST_DATA } from "../Constant/constant";

const initialState={
user_list:[],
user_details:""
}



export default function UserManagementReducer(state=initialState,action) {
    switch(action.type){
    case USER_LIST_DATA:
       
    return ({
        ...state,
        user_list:action.payload
    })
    case USER_DETAILS_DATA:
       
    return ({
        ...state,
        user_details:action.payload
    })

    
     default:
        return state;


    }

}

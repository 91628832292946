import React, { useEffect } from 'react'
import Layout from '../layouts/Layout'
import { Switch } from '@mui/material'
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AirplayIcon from '@mui/icons-material/Airplay';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
/// Image
import place_holder from "../../../images/tab/1.svg"
import DeleteModal from '../modals/DeleteModal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Update_user_detailsAction, getAllstaticapiAction, updatepassworduserAction, userdetailsAction } from '../../../store/actions/UserManagementAction';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
const Detail = () => {
    const [viewVal, setViewVal] = useState(false);
    const [preview, setPreview] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [profileImagesend, setProfileImagesend] = useState("");
    const [professional, setProfessional] = useState("");

    const [staticapi, setStaticapi] = useState("");
 
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [Company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [status, setStatus] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const handleImageUpload = (e) => {
        if (e.target.files[0]) {
          setPreview(URL.createObjectURL(e.target.files[0]));
          setProfileImagesend(e.target.files[0]);
        }
      };
const {id}=useParams();
const dispatch=useDispatch();
useEffect(()=>{
  dispatch(userdetailsAction(id))

},[id])


var user_details=useSelector((state)=>state.Usermanagement?.user_details)

useEffect(() => {
  if (user_details) {
    setProfileImage(user_details?.user_pic);
    setFullName(user_details?.full_name);
    setEmail(user_details?.email);
    setPhoneNumber(user_details?.phone_number);
    setStatus(user_details?.status);
    setProfessional(user_details?.professional)
    setCompany(user_details?.company_name)
  }
}, [user_details]);
const handleSubmit = (e) => {
  const formData = new FormData();
  formData.append("id", user_details?._id);
  profileImage && formData.append("profile_pic", profileImagesend);
  fullName && formData.append("full_name", fullName);
  email && formData.append("email", email);
  
  Company && formData.append("company", Company);
  professional && formData.append("professional", professional);

  phoneNumber && formData.append("phone_number", phoneNumber);
   dispatch(Update_user_detailsAction(formData));
};
    

const handleChangePass =useCallback( () => {
  const dataVal = {
    userId: user_details?._id,
    password: password,
  };
  if (password) {
    if (password == confirmPassword) {
      dispatch(updatepassworduserAction(dataVal));
      setPassword("")
      setConfirmPassword("")
    } else {
      toast.error("Password dosen't match");
    }
  }
});

useEffect(()=>{
  dispatch(getAllstaticapiAction()).then(function(data){
   
    setStaticapi(data);
  })
},[])


  return (
     <Layout>
                  <DeleteModal
                    viewVal={viewVal}
                    setShowVal={setViewVal}
                    
                  />
            <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="user_detail_img_inner">
                    <img
                          src={preview?preview:  user_details?.user_pic==""?place_holder:process.env.REACT_APP_FILE_BASE_URL+"profileimages/"+user_details.user_pic}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                    </div>
                    <input
                      className="user_detail_upload"
                      id="formFileSm"
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                    <div className="card-body">
                      <div className="basic-form">
                      <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Full Name"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </div>
                            {
                              user_details?.role==1 &&
                            
                            <div className="form-group mb-3 col-md-6">
                              <label>Company Name</label>
                              <input
                                type="text"
                                name="Company"
                                className="form-control"
                                placeholder="Company"
                                onChange={(e) => setCompany(e.target.value)}
                                value={Company}
                              />
                            </div>
                          }

                            <div className="form-group mb-3 col-md-6">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                name="phone_number"
                                className="form-control"
                                placeholder="Phone Number"
                                pattern="[0-9]*"
                                onChange={(e) =>
                                        setPhoneNumber((v) => (e.target.validity.valid ? e.target.value : v))}

                                value={phoneNumber}
                              />
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label>Professional</label>
                            <select    className="form-control"  onChange={(e) => setProfessional(e.target.value)} value={professional}>
                            {
                              staticapi?.professional?.map(function(object_professional,i){
                                return   <option value={object_professional._id} key={i}>{object_professional.title} </option>
                              })
                            }
                            
                            </select>
                            </div>

                            
                        
                          </div>
                          <div>
                            <button
                              onClick={() => handleSubmit()}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Other Information</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                      <ul className="user_additional_info">
                          <li>
                            <div className="user_additional_info_inner">
                            <div className="theme-change-btn"><AirplayIcon /></div> 
                            </div>
                            <div className="user_additional_info_content">
                              <p>Last Login</p>
                              <h2>11/02/2000</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              {/* <img  src={profile} alt="" /> */}
                              <div className="theme-change-btn"><SpaceDashboardIcon /></div>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Last type</p>
                              <h2>Genreal</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <div className="theme-change-btn"><CalendarMonthIcon /></div>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Registered Date</p>
                              <h2>11/02/2000</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <div className="theme-change-btn"><PhoneAndroidIcon /></div>

                            </div>
                            <div className="user_additional_info_content">
                              <p>Device Type</p>
                              <h2>N/A</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <div className="theme-change-btn"><ReportGmailerrorredIcon /></div>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Device Model</p>
                              <h2>N/A</h2>

                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <div className="theme-change-btn"><LocationOnIcon /></div>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Country</p>
                              <h2>N/A</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <div className="theme-change-btn"><PermIdentityIcon /></div>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Gender</p>
                              <h2>N/A</h2>
                            </div>
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
     
            <div className="col-xl-6 col-lg-12">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="card colum-height">
                    <div className="card-header">
                      <h4 className="card-title">Deactivate Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form d-flex">
                          <div className="form-check switch custom-checkbox mb-3 checkbox-success">
                          <Switch />
                            </div>
                          <h6 className="ms-2">
                            You will not be able to receive messages,
                            notifications for up to 24 hours.
                          </h6>
                      
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="card colum-height">
                    <div className="card-header">
                      <h4 className="card-title">Delete Account</h4>
                    </div>
                    <div className="card-body">
                    <div className="row">
                      
                    </div>
                      <div className="basic-form">
                        <h6>
                          Once you delete the account, there is no going back.
                          Please be certain.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-12">
                            <div className="form-check custom-checkbox mb-3 checkbox-success delete_account">
                              <button
                                className="btn btn-danger "
                                onClick={()=>{
                                          setViewVal(true); 
                                }
                               }
                              >
                                Delete my account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Reset Password</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    {/* <form> */}
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                    <div className="row"></div>
                    <div className="form-group">
                      <div className=" "></div>
                    </div>
                    <button
                      onClick={() => {
                        handleChangePass();
                      }}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </Layout>
  )
}

export default Detail